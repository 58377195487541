<template>
  <div class="main_v8">
    <div class="data_operations">  
      <div class="controls" v-if="!desktop">
        <h3 @click="here()">Op Monitoring</h3>
        <div class="dropdowns" v-if="!desktop">
          <div class="dropdown">
            <span>Location </span> <locationDrop class="locationDropDisp" :startingid="0" :states=locationStates :enabled=locationDropDownEnabled @selection="locationSelected"></locationDrop>
          </div>
          <div class="dropdown">
            <span>Vehicle </span> <vehicleDrop class="vehicleDropDisp" :startingid="0" :states=vehicleStates :enabled=vehicleDropDownEnabled @selection="vehicleSelected"></vehicleDrop>
          </div>
        </div>
      </div>
      
      <div class="data_block">
        
        <div class="mts_data" v-if="displayData == 'mts' || desktop">
          <!-- MTS -->
          
          <div class="local_dropdowns" v-if="desktop">
            <h3 @click="here()">MTS Dashboard</h3>
            <div class="dropdown">
              <mtslocationDrop class="locationDropDisp" :startingid="0" :states=mtsLocationStates :enabled=mtsLocationDropDownEnabled @selection="mtsLocationSelected"></mtslocationDrop>
            </div>
            <div class="dropdown">
              <mtsvehicleDrop class="vehicleDropDisp" :startingid="0" :states=mtsVehicleStates :enabled=mtsVehicleDropDownEnabled @selection="mtsVehicleSelected"></mtsvehicleDrop>
            </div>
          </div>

          <div class="mts">
            <div class="mts_left">
              <radialDisp class="radialDisp" v-if="mtsresult" :valin="mtsresult['MachineStatus Radial Displacement']" ></radialDisp>
              <steeringAngle class="steeringAngleDisp" v-if="mtsresult" :valin_actual="mtsresult['MachineStatus Actual Steering Angle']" :valin_theoretical="mtsresult['MachineStatus Theoretical Steering Angle']"></steeringAngle>
            </div>

            <div class="mts_center">
              <div>
                <dial1 class="dial" v-if="mtsresult" :valin="mtsresult['MachineStatus Brake Force']" :title="'Brake'"></dial1>
              </div>
              <div>
                <dial1 class="dial" v-if="mtsresult" :valin="mtsresult['MachineStatus Current Speed Feedback']" :title="'IPS'"></dial1>
              </div>
              <div>
                <stateInd1 v-if="mtsresult" class="op_mode" :valin="opmode" :shape="0" :states=textStates></stateInd1>
                <p>Operational Mode</p>
              </div>
              <div>
                <dial1 v-if="mtsresult" class="dial" :valin="mtsresult['MachineStatus Swing Tail Opening']" ></dial1>
                <p>Swing Tail Opening</p>
              </div>
              <div>
                <dial1 v-if="mtsresult" class="dial" :valin="mtsresult['MachineStatus Angle Of Lvc In Relationship To Aircraft']" ></dial1>
                <p>Lift Vehicle Chassis</p>
              </div>
            </div>

            <div class="mts_right">
              <div class="eight_gages">
                <div v-if="mtsresult">
                  <p>Tangential Load (Ibs)</p>
                  <div class="eight_dial_standalone">{{ mtsresult['Compliance P1 Tangentail Load Feed Back'] }}</div>
                </div>
                <div>
                  <p>System in By–Pass</p>
                  <ind2 v-if="mtsresult" class="eight_light" :valin="mtsresult['MachineStatus System In Bypass']" :shape="0" :states=colorStates></ind2>
                </div>
                <div v-if="mtsresult">
                  <p>A/P Load (Ibs)</p>
                  <div class="eight_dial_standalone">{{ mtsresult['MachineStatus AP Load'] }}</div>
                </div>
                <div>
                  <p>P257</p>
                  <ind2 v-if="mtsresult" class="eight_light" :valin="mtsresult['MachineStatus P257 Cables Connected']" :shape="0" :states=colorStates></ind2>
                </div>
                <div v-if="mtsresult">
                  <p>Direction</p>
                  <div class="eight_dial_standalone" v-if="mtsresult['MachineStatus Slow Forward'] == 1">FWD</div>
                  <div class="eight_dial_standalone" v-else-if="mtsresult['MachineStatus Slow Reverse'] == 1">REV</div>
                  <div class="eight_dial_standalone" v-else-if="mtsresult['MachineStatus Fast Forward'] == 1">FWD</div>
                  <div class="eight_dial_standalone" v-else-if="mtsresult['MachineStatus Fast Reverse'] == 1">REV</div>
                  <div class="eight_dial_standalone" v-else-if="mtsresult['MachineStatus Neutral Position'] == 1">N</div>
                  <div class="eight_dial_standalone" v-else>...</div>
                </div>
                <div>
                  <p>Parking</p>
                  <ind2 v-if="mtsresult" class="eight_light" :valin="mtsresult['MachineStatus Parking Brake']" :shape="0" :states=colorStates></ind2>
                </div>
                <div>
                  <p>Transport Mode</p>
                  <ind2 v-if="mtsresult" class="eight_light" :valin="mtsresult['MachineStatus Transport Speed Enabled']" :shape="0" :states=colorStates></ind2>
                </div>
                <div>
                  <p>Home</p>
                  <ind2 v-if="mtsresult" class="eight_light" :valin="mtsresult['MachineStatus Home']" :shape="0" :states=colorStates></ind2>
                </div>
              </div>
              <div class="wevlet">
                <wevlet v-if="mtsresult"
                        :hydPressure="mtsresult['MastHydraulic H4 Hydraulic Pressure Sensor']" 
                        :nitPressure="mtsresult['MastHydraulic H9 Nitrogen Pressure Sensor']" 
                        :mastLevel="mtsresult['MastHydraulic H7 Accumulator Piston Stroke']" 
                        :hydLevel="mtsresult['MachineStatus Mast Height']" 
                        :leftMast="mtsresult['MachineStatus Mast Height']"
                        :dirtyFilter="mtsresult['MastHydraulic Dirty Filter Switch']" 
                        :problemValve1="mtsresult['MastHydraulic H5 Lh Cylinder Blocking Valve']"
                        :problemValve2="mtsresult['MastHydraulic H6 Rh Cylinder Blocking Valve']"
                        :problemValve3="mtsresult['MastHydraulic H1 Main Blocking Solenoid']">
                </wevlet>
                <wevlet v-if="!mtsresult"
                        :hydPressure=0
                        :nitPressure=0
                        :mastLevel=0
                        :hydLevel=0
                        :leftMast=0
                        :dirtyFilter=0
                        :problemValve1=0
                        :problemValve2=0
                        :problemValve3=0>
                </wevlet>
              </div>
            </div>
          </div>
          <div class="mts_bottom">
            <p>MTS Alarms <div class="button" @click="GetMtsReport">Generate MTS report</div></p>
            <div style="overflow: scroll; height: 124px">
              <ul style="padding-left: 16px">
						    <li v-if="mtsalarmsresult!=-2" v-for="line in mtsalarmsresult" :key="line">{{line}}</li>
					    </ul>
            </div>
          </div>
        </div>

        <div class="lcl_data" v-if="displayData == 'lcl' || desktop">
          <div class="local_dropdowns" v-if="desktop">
            <h3 @click="here()">LCL Dashboard</h3>
            <div class="dropdown">
              <lcllocationDrop class="locationDropDisp" :startingid="0" :states=lclLocationStates :enabled=lclLocationDropDownEnabled @selection="lclLocationSelected"></lcllocationDrop>
            </div>
            <div class="dropdown">
              <lclvehicleDrop class="vehicleDropDisp" :startingid="0" :states=lclVehicleStates :enabled=lclVehicleDropDownEnabled @selection="lclVehicleSelected"></lclvehicleDrop>
            </div>
          </div>

          <div class="lcl">
            <div class="lcl_left">
              <div v-if="lclresult" class="bypass">
                <!-- <dial1 v-if="lclresult" class="dial" :valin="lclresult['Hydraulics Hydraulic Level Level 1']" ></dial1> -->
                <div v-if="lclresult['MachineStatus Bypass Active'] == 0"></div>
                <div class="blinking" v-else-if="lclresult['MachineStatus Bypass Active'] == 1"></div>
                <div v-else>N/A</div>
                <p>System In Bypass</p>
              </div>

              <div v-if="lclresult" class="speed_guidance_system">
                <!-- <dial1 class="dial" v-if="lclresult" :valin="lclresult['MachineStatus Active Bypass Slow Speed']"></dial1> -->
                <div v-if="lclresult['MachineStatus Active Bypass Slow Speed'] == 0"></div>
                <div class="is_on" v-else-if="lclresult['MachineStatus Active Bypass Slow Speed'] == 1"></div>
                <div v-else></div>
                <p>Slow Speed</p>
              </div>
              <div v-if="lclresult" class="speed_guidance_system">
                <!-- <dial1 v-if="lclresult" class="dial" :valin="lclresult['MachineStatus Guidance']" :states=textStates></dial1> -->
                <div v-if="lclresult['MachineStatus Guidance'] == 0"></div>
                <div class="is_on" v-else-if="lclresult['MachineStatus Guidance'] == 1"></div>
                <div v-else></div>
                <p>Guidance System</p>
              </div>
              <div v-if="lclresult" class="speed_guidance_system">
                <!-- <dial1 v-if="lclresult" class="dial" :valin="lclresult['Transfer Transfer Enabled']" ></dial1> -->
                <div v-if="lclresult['Transfer Transfer Enabled'] == 0"></div>
                <div class="is_on" v-else-if="lclresult['Transfer Transfer Enabled'] == 1"></div>
                <div v-else></div>
                <p>Transfer Mode</p>
              </div>


              <div v-if="lclresult" class="hydraulic">
                <!-- <dial1 v-if="lclresult" class="dial" :valin="lclresult['Hydraulics Hydraulic Level Level 1']" ></dial1> -->
                <div v-if="lclresult['Hydraulics Hydraulic Level Level 1'] == 1" style="border: 3px solid #e11616; box-sizing: border-box;">LOW</div>
                <div v-else-if="lclresult['Hydraulics Hydraulic Level Level 1'] == 0">OK</div>
                <div v-else>OK</div>
                <p>Hydraulic Level</p>
              </div>
              <div>
                <dial2 v-if="lclresult" class="dial2" :valin="Math.ceil(lclresult['Hydraulics Hydraulic Temperature'])" ></dial2>
                <p>Hydraulic Oil Temp (F)</p>
              </div>
              <div v-if="lclresult" class="stabilizer">
                <div v-if="lclresult['MachineStatus Stabilizer Switch Status Up'] == 1">Up</div>
                <div v-else-if="lclresult['MachineStatus Stabilizer Switch Status Down'] == 1">Down</div>
                <div class="blinking" v-else-if="lclresult['MachineStatus Stabilizer Switch Status Moving'] == 1">Moving</div>
                <div v-else>N/A</div>
                <p>Stabilizer Position</p>
              </div>
            </div>

            <div class="lcl_middle">
              <div class="angle_displays">
                <rollAngle v-if="lclresult" class="rollAngleDisp" :valin="lclresult['MachineStatus Roll Angle']" ></rollAngle>
                <heightAndPitch v-if="lclresult" class="heightAndPitchDisp" :val_left="lclresult['MachineStatus Rear Height']" :val_right="lclresult['MachineStatus Front Height']"></heightAndPitch>
                <yaw v-if="lclresult" class="yawDisp" :val_left="lclresult['MachineStatus Rear Side Shift']" :val_right="lclresult['MachineStatus Front Side Shift']"></yaw>
                <!-- <imageInd1 v-if="lclresult" class="imageIndDisp" :valin="lclresult['MachineStatus Driving Mode']" :images=imageStates></imageInd1> -->
                <dial1 v-if="lclresult" class="fuel" style="font-size: 0.62rem; width: 80px; height: 80px;" :valin="Math.ceil(lclresult['MachineStatus Fuel Level']/325.04)" :title="'Fuel'"></dial1>
                <dial1 v-if="lclresult" class="mph" style="font-size: 0.62rem;" :valin="Math.ceil(lclresult['MachineStatus Drive Speed'])" :title="'MPH'"></dial1>
              </div>

              <div v-if="lclresult" class="pfr">
                <div class="wheels">Wheels</div>
                <dial1 class="dial" :valin="lclresult['MachineStatus Wheel Angle Front Left']" :title="'angle'"></dial1>
                <dial1 class="dial" :valin="lclresult['MachineStatus Wheel Angle Front Right']" :title="'angle'"></dial1>

                <div v-if="lclresult['MachineStatus Current Speed Feedback']/1 == 1 && 4 < lclresult['Drive Direction Indicator']/1" class="dir" :title="'Direction'">Left</div>
                <div v-else-if="lclresult['MachineStatus Current Speed Feedback']/1 == 1" class="dir" :title="'Direction'">Forward</div>
                <div v-else-if="lclresult['MachineStatus Drive Direction Rear']/1 == 1 && 4 < lclresult['Drive Direction Indicator']/1" class="dir" :title="'Direction'">Right</div>
                <div v-else-if="lclresult['MachineStatus Drive Direction Rear']/1 == 1" class="dir" :title="'Direction'">Reverse</div>
                <div v-else-if="lclresult['MachineStatus Drive Direction No Direction'] == 1" class="dir" :title="'Direction'">No Direction</div>
                <div v-else class="dir" :title="'Direction'">N/A</div>

                <dial1 class="dial" :valin="lclresult['MachineStatus Wheel Angle Rear Left']" :title="'angle'"></dial1>
                <dial1 class="dial" :valin="lclresult['MachineStatus Wheel Angle Rear Right']" :title="'angle'"></dial1>

                <div v-if="lclresult['MachineStatus Drive Mode Front and Rear Steering'] == 1" class="drive_mode" :title="'Drive Mode'">Front/Rear</div>
                <div v-else-if="lclresult['MachineStatus Drive Mode Crab Steering'] == 1" class="drive_mode" :title="'Drive Mode'">Crab</div>
                <div v-else-if="lclresult['MachineStatus Drive Mode Front Wheel Steering Only'] == 1" class="drive_mode" :title="'Drive Mode'">Front Only</div>
                <div v-else-if="lclresult['MachineStatus Drive Mode Rear Wheel Steering Only'] == 1" class="drive_mode" :title="'Drive Mode'">Rear Only</div>
                <div v-else-if="lclresult['MachineStatus Drive Mode Carousel'] == 1" class="drive_mode" :title="'Drive Mode'">Carousel</div>
                <div v-else-if="lclresult['MachineStatus Drive Mode Sideways'] == 1" class="drive_mode" :title="'Drive Mode'">Sideways</div>
                <div v-else class="drive_mode" :title="'Drive Mode'">N/A</div>

                
              </div>
            </div>
            <div class="lcl_right">
              <threegauges v-if="lclresult" class="double_side_disp" :title="'rpm'" :valin_one="lclresult['Engine1 RPM']/20" :valin_two="lclresult['Engine2 RPM']/20"></threegauges>
              <threegauges v-if="lclresult" class="double_side_disp" :title="'oil'" :subtitle="'psi'" :valin_one="lclresult['Engine1 Engine Oil Pressure']/1.001" :valin_two="lclresult['Engine2 Engine Oil Pressure']/1.001"></threegauges>
              <threegauges v-if="lclresult" class="double_side_disp" :title="'temp'" :subtitle="'° F'" :valin_one="lclresult['Engine1 Water Temperature']/2" :valin_two="lclresult['Engine2 Water Temperature']/2"></threegauges>
            </div>
          </div>

          <div class="lcl_bottom">
            <p>LCL Alarms <div class="button" @click="GetLclReport">Generate LCL report</div></p>
            <div style="overflow: scroll; height: 124px">
              <ul style="padding-left: 16px">
						    <li v-if="lclalarmsresult!=-2" v-for="line in lclalarmsresult" :key="line">{{line}}</li>
					    </ul>
            </div>
          </div>
        </div>
      
      </div>


    </div>
    <livedatalcl :src="lclurl"
            :scanrate="scanrate"
            :controls=false
            @on-data="UpdateLclData">
    </livedatalcl>
    <livedatamts :src="mtsurl"
            :scanrate="scanrate"
            :controls=false
            @on-data="UpdateMtsData">
    </livedatamts>
    <livealarmslcl :src="lclalarmsurl"
            :scanrate="scanrate"
            :controls=false
            @on-data="UpdateLclAlarmData">
    </livealarmslcl>
    <livealarmsmts :src="mtsalarmsurl"
            :scanrate="scanrate"
            :controls=false
            @on-data="UpdateMtsAlarmData">
    </livealarmsmts>
  </div>
</template>

<script setup>
  import { ref, computed, onMounted } from 'vue';
  import { useStore } from 'vuex';
  const store = useStore();
  import livedatalcl from '../components/livedata/data2.vue';
  import livedatamts from '../components/livedata/data2.vue';
  import livealarmsmts from '../components/livedata/data2.vue';
  import livealarmslcl from '../components/livedata/data2.vue';
  import radialDisp from '../components/boeing/radialDisplacementGauge.vue';
  import steeringAngle from '../components/boeing/steeringAngleGauge.vue';
  import dial1 from '../components/boeing/dialGauge.vue';
  import dial2 from '../components/boeing/dialGauge2.vue';
  import ind1 from '../components/boeing/indicator.vue';
  import ind2 from '../components/boeing/indicator.vue';
  import stateInd1 from '../components/boeing/textIndicator.vue';
  import wevlet from '../components/boeing/wevlet.vue';
  import rollAngle from '../components/boeing/levelGauge.vue';
  import heightAndPitch from '../components/boeing/heightAndPitchGauge.vue';
  import yaw from '../components/boeing/yawGauge.vue';
  import imageInd1 from '../components/boeing/imageStateIndicator.vue';
  import locationDrop from '../components/dropdowns/dropdown2.vue';
  import vehicleDrop from '../components/dropdowns/dropdown2.vue';
  import mtslocationDrop from '../components/dropdowns/dropdown2.vue';
  import mtsvehicleDrop from '../components/dropdowns/dropdown2.vue';
  import lcllocationDrop from '../components/dropdowns/dropdown2.vue';
  import lclvehicleDrop from '../components/dropdowns/dropdown2.vue';

  import doubleSide from '../components/boeing/doubleSide.vue';
  import threegauges from '../components/boeing/3gauges.vue';
  
  const dataurl = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getCriticalValuesv8.php";
  const alarmurl = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getAlarmHistory.php?time=123456&dropletid=";
  let desktop = ref(window.screen.width >= 1440 ? true : false);
  let displayData = ref('');
  let startstop = ref(0);
  let lclresult = ref();
  let mtsresult = ref();
  let lclalarmsresult = ref();
  let mtsalarmsresult = ref();
  let imageStates = ref("");
let windowWidth = ref(window.innerWidth);
let locations = {
  "LCL1": {
    id: 110,
    loc: "NGO",
  },
  "LCL3": {
    id: 111,
    loc: "NGO",
  },
  "MTS1": {
    id: 100,
    loc: "NGO",
  },
  "MTS4": {
    id: 101,
    loc: "NGO",
  },
  "LCL2": {
    id: 210,
    loc: "TAR",
  },
  "LCL4": {
    id: 211,
    loc: "TAR",
  },
  "MTS2": {
    id: 200,
    loc: "TAR",
  },
  "MTS3": {
    id: 201,
    loc: "TAR",
  },
  "LCL5": {
    id: 310,
    loc: "IAB",
  },
  "LCL7": {
    id: 311,
    loc: "IAB",
  },
  "MTS5": {
    id: 300,
    loc: "IAB",
  },
  "MTS7": {
    id: 301,
    loc: "IAB",
  },
  "LCL6": {
    id: 410,
    loc: "PAE",
  },
  "LCL8": {
    id: 411,
    loc: "PAE",
  },
  "MTS6": {
    id: 400,
    loc: "PAE",
  },
  "MTS9": {
    id: 401,
    loc: "PAE",
  },
  "LCL9": {
    id: 500,
    loc: "CHS",
  },
  "LCL10": {
    id: 501,
    loc: "CHS",
  },
  "MTS8": {
    id: 510,
    loc: "CHS",
  },
  "MTS10": {
    id: 511,
    loc: "CHS",
  }
};
  imageStates.value = {
    "3":"https://boeing.dreamlifter.wave7.co/screens/projects/BDL/assets/BDL-Mode-All.svg",
    "5":"https://boeing.dreamlifter.wave7.co/screens/projects/BDL/assets/BDL-Mode-Carousel.svg",
    "4":"https://boeing.dreamlifter.wave7.co/screens/projects/BDL/assets/BDL-Mode-Crab.svg",
    "1":"https://boeing.dreamlifter.wave7.co/screens/projects/BDL/assets/BDL-Mode-Front.svg",
    "0":"https://boeing.dreamlifter.wave7.co/screens/projects/BDL/assets/BDL-Mode-None.svg",
    "2":"https://boeing.dreamlifter.wave7.co/screens/projects/BDL/assets/BDL-Mode-Rear.svg",
    "6":"https://boeing.dreamlifter.wave7.co/screens/projects/BDL/assets/BDL-Mode-Sideways.svg",
    "undefined":"https://boeing.dreamlifter.wave7.co/screens/projects/BDL/assets/BDL-Mode-None.svg"
  };

  onMounted(() => {
    setDesktop();
    window.addEventListener("resize", setDesktop);
    UpdateData();
    if (store.getters.getLocation != '') {
      openMachine();
    }
    // console.log(store.getters.getLocation,'location to load');
    // mtsVehicleSelected(100);
    // lclVehicleSelected(210);
  });
  function openMachine() {
    let machine = store.getters.getLocation;
    let machineID = locations[machine].id;
    if (desktop.value && machine.toLowerCase().includes('lcl')) {
      lclVehicleSelected(machineID);
    } else if (desktop.value && machine.toLowerCase().includes('mts')) {
      mtsVehicleSelected(machineID);
    } else { 
      vehicleSelected(machineID);
    }
    // console.log(machineID,'123')
  };

  let setDesktop = (() => {
    desktop.value = window.innerWidth >= 1440 ? true : false
  });

  window.addEventListener("resize", desktop);

  const fuelLevel = computed(() => {
    const val = lclresult.value['MachineStatus Fuel Level'];
    return 100 * (val / 32568).toFixed(0);
  })
  function here() {
    // console.log(mtsVehicleStates, 'lclLocationStates')
    // console.log(lclVehicleStates, 'lclLocationStates')
  };

  const wevletDataReady = computed(() => {
    if(mtsresult['MastHydraulic H4 Hydraulic Pressure Sensor'] && 
        mtsresult['MastHydraulic H9 Nitrogen Pressure Sensor'] && 
        mtsresult['MachineStatus Mast Height']  && 
        mtsresult['MachineStatus Mast Height'] &&
        mtsresult['MachineStatus Mast Height'] &&
        mtsresult['MastHydraulic Dirty Filter Switch'] &&
        mtsresult['MastHydraulic H5 Lh Cylinder Blocking Valve'] && 
        mtsresult['MastHydraulic H6 Rh Cylinder Blocking Valve'] && 
        mtsresult['MastHydraulic H1 Main Blocking Solenoid']) {
      return true;
    } else {
      return false;
    }
  })

  const direction = computed(() => {
    const val = mtsresult.value;
    if(val['MachineStatus Slow Forward'] == "1")
    {
        return "FWD";
    }
    else if(val['MachineStatus Slow Reverse'] == "1")
    {
        return "REV";
    }
    else if(val['MachineStatus Fast Forward'] == "1")
    {
        return "FWD";
    }
    else if(val['MachineStatus Fast Reverse'] == "1")
    {
        return "REV";
    }
    else if(val['MachineStatus Neutral Position'] == "1")
    {
        return "N";
    }
    else
    {
        return "N/A";
    }
  });

  const opmode = computed(() => {
    const val = mtsresult.value;
    if(val['MachineStatus Low Speed Arc Travel'] == "1")
    {
        return 0;
    }

    else if(val['MachineStatus High Speed Arc Travel'] == "1")
    {
        return 1;
    }

    else if (val['MachineStatus Arc Travel Authorized'] == "1")
    {
        return 2;
    }

    else if((val['MachineStatus Phase 3 Mast Lift'] == "1") || (val['MachineStatus Phase 3 Mast Lowering'] == "1"))
    {
        return 3;
    }

    else if((val['MachineStatus Phase 2 Mast Lift'] == "1") || (val['MachineStatus Phase 2 Mast Lowering'] == "1"))
    {
      return 4;
    }
    else if((val['MachineStatus Phase 1 Mast Lift'] == "1") || (val['MachineStatus Phase 1 Mast Lowering'] == "1"))
    {
      return 5;
    }
    else
    {
        return 6;
    }
  })
  
  let textStates = ref("");
  textStates.value = [
    "Low Speed",
    "High Speed",
    "Authorized",
    "Assuming Load / Unloading",
    "Hook Ups / Disconnecting",
    "Alignment / Disembark",
    ""
  ];

  let colorStates = ref("");
  colorStates.value = [
    "#333333",
    "#00a859"
  ];

  //----------------- LOCATION AND VEHICLE DROP DOWN SELECTION -------------------------------
  let mtsurl = ref("");
  let lclurl = ref("");
  let mtsalarmsurl = ref("");
  let lclalarmsurl = ref("");
  const scanrate = ref(5000);
  let currentDropletId = "";
  let mtsCurrentDropletId = "";
  let lclCurrentDropletId = "";

  let locationDropDownEnabled = ref(true);
  let mtsLocationDropDownEnabled = ref(true);
  let lclLocationDropDownEnabled = ref(true);

  let vehicleDropDownEnabled = ref(false);
  let mtsVehicleDropDownEnabled = ref(false);
  let lclVehicleDropDownEnabled = ref(false);

  let locationStates = ref("");
  let mtsLocationStates = ref("");
  let lclLocationStates = ref("");

  let vehicleStates = ref("");
  let mtsVehicleStates = ref("");
  let lclVehicleStates = ref("");


  locationStates.value = [];
  mtsLocationStates.value = [];
  lclLocationStates.value = [];

  vehicleStates.value = [];
  mtsVehicleStates.value = [];
  lclVehicleStates.value = [];

  const getLocationsUrl = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?locSel";
  const getVehicleUrl = "https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/getTags.php?dropSel&location=";

 
  fetch(getLocationsUrl, {
    headers: { 'Content-type': 'application/json' },
    }).then((res) => res.json()).then((response) => {
        console.log("LOCATIONS:",response);
        locationStates.value = response;
        mtsLocationStates.value = response;
        lclLocationStates.value = response;
        locationDropDownEnabled.value = true;
        mtsLocationDropDownEnabled.value = true;
        lclLocationDropDownEnabled.value = true;
    }).catch((error) => {
        console.log("LOCATION FETCH ERROR " + error);
  });

  function mtsLocationSelected(id) {
    mtsVehicleStates.value = {0:""};
    fetch(getVehicleUrl+id, {
      headers: { 'Content-type': 'application/json' },
      }).then((res) => res.json()).then((response) => {
        Object.entries(response).forEach(function (key) {
            if (response[key[0]].includes('MTS')) {
              mtsVehicleStates.value[key[0]] = response[key[0]];
            }
        });
        mtsVehicleDropDownEnabled.value = true;
      }).catch((error) => {
          console.log("MTS VEHICLE FETCH ERROR " + error);
    });
  }

  function lclLocationSelected(id) {
    lclVehicleStates.value = {0:""};
    fetch(getVehicleUrl+id, {
      headers: { 'Content-type': 'application/json' },
      }).then((res) => res.json()).then((response) => {
        Object.entries(response).forEach(function (key) {
            if (response[key[0]].includes('LCL')) {
              lclVehicleStates.value[key[0]] = response[key[0]];
            }
        });
        lclVehicleDropDownEnabled.value = true;
      }).catch((error) => {
          console.log("LCL VEHICLE FETCH ERROR " + error);
    });
  }

  function locationSelected(id) {
    vehicleStates.value = {0:""};
    fetch(getVehicleUrl+id, {
      headers: { 'Content-type': 'application/json' },
      }).then((res) => res.json()).then((response) => {
        Object.entries(response).forEach(function (key) {
            if (response[key[0]]) {
              vehicleStates.value[key[0]] = response[key[0]];
            }
        });
        vehicleDropDownEnabled.value = true;
      }).catch((error) => {
          console.log("VEHICLE FETCH ERROR " + error);
    });
  }
              
  function vehicleSelected(id) {
    currentDropletId = id;
    if (id == '100' || id == '511' || id == '200' || id == '201' || id == '101' || id == '300' || id == '400' || id == '301' || id == '510' || id == '401') {
      mtsurl.value = dataurl + "?MTS&dropletid=" + id;
      displayData.value = 'mts';
    } else if (id == '110' || id == '501' || id == '210' || id == '111' || id == '211' || id == '310' || id == '410' || id == '311' || id == '411' || id == '500') {
      lclurl.value = dataurl + "?LCL&dropletid=" + id;
      displayData.value = 'lcl';
    } else {
      currentDropletId = '';
      displayData.value = '';
    }
  }

  function mtsVehicleSelected(id) {
    mtsCurrentDropletId = id;
    mtsurl.value = dataurl + "?MTS&dropletid="+id;
    mtsalarmsurl.value = alarmurl + id;
    console.log("1111111111111111");
    // console.log("MTS-ALARM-URL",mtsalarmsurl.value);

  }

  function lclVehicleSelected(id) {
    lclCurrentDropletId = id;
    lclurl.value = dataurl + "?LCL&dropletid="+id;
    lclalarmsurl.value = alarmurl + id;
    // console.log("LCL-ALARM-URL",lclalarmsurl.value);
  }
  //----------------------------END OF LOCATION AND VEHICLE DROP DOWNS -----------------------------

  function UpdateLclData(newdata) {
    lclresult.value = newdata;
    // console.log("LCLDATA",lclresult.value);
  }

  function UpdateMtsData(newdata) {
    //console.log("MTSDATA",newdata);
    mtsresult.value = newdata;
  }

  function UpdateLclAlarmData(newdata) {
    lclalarmsresult.value = newdata;
    //console.log("LCLALARMDATA",lclalarmsresult.value);
  }

  function UpdateMtsAlarmData(newdata) {
    mtsalarmsresult.value = newdata;
    //console.log("MTSALARMDATA",mtsalarmsresult.value);
  }

  function UpdateData(newdata) {
    // mtsresult.value = newdata;
  }

  function GetMtsReport() {
    fetch("https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/bdl_alarmhistoryv8.php?dropletid="+mtsCurrentDropletId, {
      }).then((res) => res.json()).then((response) => {
        console.log("MTS REPORT FILENAME",response[0])
        DownloadFile(response[0]);
      }).catch((error) => {
          console.log("GET MTS REPORT FETCH ERROR " + error);
    });
  }

  function GetLclReport() {
    fetch("https://boeing.dreamlifter.wave7.co/screens/projects/BDL/scripts/bdl_alarmhistoryv8.php?dropletid="+lclCurrentDropletId, {
      }).then((res) => res.json()).then((response) => {
        DownloadFile(response[0]);
      }).catch((error) => {
          console.log("GET LCL REPORT FETCH ERROR " + error);
    });
  }

  async function DownloadFile(file) {
    try {
        const url = encodeURI("https://boeing.dreamlifter.wave7.co/uploads/" + file);
        // console.log("FILENAME",fileName);
        // const response = await fetch(fileName)
        // const blob = await response.blob();
        // const url = await URL.createObjectURL(blob)

        const a = document.createElement("a");
        a.href = url;
        a.download = file;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } catch(err) {
        console.log({ err })
    }
  }

</script>

<style lang="scss" scoped>
  .main_v8 {
    // top: 64px !important;
    padding: 0px;
    background-color: #fff;
    @media screen and (max-width: 480px), screen and (max-height: 480px) and (orientation: landscape) {
      bottom: 65px;
    }
  }
  .data_block{
    height: -webkit-fill-available;
    @media screen and (min-width: 1440px) {
      display: flex;
      flex-direction: row;
      >div{
        width: 50%;
        font-size: 13px;
        line-height: 16px;
      }
    }
    
    @media screen and (max-width: 1439px) {
      height: 100%;
    }
  }
  .data_operations{
    background-color: #fff;
    border-radius: 12px;
    box-sizing: border-box;
    height: 100%;
    font-size: 19px;
    display: flex;
    flex-direction: column;
    // @media screen and (max-width: 1500px) {
    //   height: initial;
    // }
    @media screen and (max-width: 1300px) {
      position: relative;
      width: 100%;
    }
    @media screen and (max-width: 700px) {
      height: auto;
      margin-bottom: 15px;
    }
    .controls{
      height: 74px;
      background-color: #f2f2f2;
      padding: 0 20px;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 1440px) {
        display: flex;
        // overflow: hidden;
      }
      @media screen and (max-width: 700px) {
        flex-direction: column;
        height: auto;
        align-items: start;
        padding: 0px 20px 10px;
      }
      h3{
        margin: 0 20px 0 0;
        height: 74px;
        display: flex;
        align-items: center;
        @media screen and (max-width: 700px) {
          height: 55px;
          line-height: 55px;
        }
      }
      .dropdowns{
        display: flex;
        flex-direction: row;
        align-items: center;
        // @media screen and (max-width: 1000px) {
        //   flex-direction: column;
        //   margin: 10px 0;
        //   align-items: end;
        // }
        @media screen and (max-width: 800px) {
          align-items: baseline;
        }
        @media screen and (max-width: 700px) {
          width: 100%;
          margin: 0 0 10px 0;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: space-between;
        }
        .dropdown{
          display: flex;
          flex-direction: row;
          margin-right: 15px;
          align-items: center;
          @media screen and (max-width: 1420px) {
            flex-direction: column;
            align-items: baseline;
          }
          @media screen and (max-width: 1300px) {
            flex-direction: row;
            align-items: center;
          }
          @media screen and (max-width: 800px) {
            flex-direction: column;
            align-items: baseline;
            width: calc(50% - 7px);
            margin-right: 0px;
          }
          .custom-select{
            height: 100%;
          }
          >span{
            margin-right: 10px;
            @media screen and (max-width: 700px) {
              align-self: baseline;
              margin-bottom: 5px;
            }
          }
        }
        .dropdown:last-child{
          
          @media screen and (max-width: 1000px) {
            margin-left: 10px; 
          }
        }
        .locationDropDisp,.vehicleDropDisp {
          height: 100%;
          width: 165px;
          @media screen and (max-width: 700px) {
            width: 100%;
          }
        }
      }
    }
    .lcl_data,.mts_data{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-grow: 1;
      flex-wrap: wrap;
      padding-bottom: 15px;
      box-sizing: border-box;
      max-height: calc(100% - 265px);
      @media screen and (min-width: 1440px){
        max-height: none;
        position: absolute;
        top: 0px;
        bottom: 0px;
      }
      @media screen and (max-width: 1439px){
        max-width: 850px;
        margin: auto;
        height: 100%;
      }
      >div:not(:last-child){
        @media screen and (min-width: 1500px){
          // margin-right: 20px;
        }
        @media screen and (min-width: 520px){
          // margin-right: 10px;
        }
      }
      .lcl,.mts{
        display: flex;
        width: 100%;
        justify-content: space-between;
        // height: auto;
        flex-grow: 1;
        align-self: stretch;
        @media screen and (max-width: 820px) {
          flex-wrap: wrap;
          justify-content: center;
        }
      }
      .lcl_bottom,.mts_bottom{
        width: calc(100% - 30px);
        height: 180px;
        box-shadow: 0px 2px 8px 0px #0000000F;
        border: 1px solid #bcbcbc;
        border-radius: 16px;
        box-sizing: border-box;
        overflow: hidden;
        margin: 0 auto;
        margin-top: auto;
        // @media screen and (min-width: 1440px) {
        //   margin: 0 15px;
        // }
        @media screen and (max-width: 560px) {
          margin: 0;
          width: 100%;
        }
        p{
          background-color: #f8f8f8;
          margin: 0;
          height: 56px;
          padding: 0 10px;
          color: #787878;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 1rem;
          .button{
            float: right;
            padding: 0 30px;
            text-transform: capitalize;
            @media screen and (max-width: 560px) {
              line-height: 22px;
              padding: 0 10px;
              display: flex;
              align-items: center;
            }
          }
        }
        ul{
          list-style-type: none;
        }
      }
      .local_dropdowns{
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 60px;
        background-color: #f2f2f2;
        padding: 0 20px;
        align-items: center;
        h3{
          margin: 0;
          margin-right: auto;
        }
        >div{
          width: 163px;
        }
        >div:last-child{
          margin-left: 10px;
        }
      }
    }
    .mts_data{
      @media screen and (min-width: 1440px) {
        border-right: 1px solid #787878;
        box-sizing: border-box;
      }
      @media screen and (max-width: 820px) {
        // flex-direction: column;
        position: relative;
        bottom: auto;
      }
      @media screen and (max-width: 920px) {
        padding: 15px 15px 0;
      }
      .mts_left{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 32px;
        @media screen and (max-width: 820px) {
          margin: 0;
          flex-direction: row;
          width: 100%;
          padding-left: 0;
        }
        @media screen and (max-width: 480px) {
          margin: 0;
          flex-direction: column;
          align-items: center;
        }
        .steeringAngleDisp,.radialDisp {
          height: 48%;
          aspect-ratio: 1/1;
          @media screen and (max-width: 820px) {
            margin-right: 10px;
            width: 38%;
            height: auto;
          }
          @media screen and (max-width: 480px) {
            width: 70%;
            margin-right: 0px;
            margin-bottom: 10px;
          }
        }
      }
      .mts_center{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 0;
        @media screen and (max-width: 820px) {
          flex-direction: initial;
          justify-content: space-around;
          flex-wrap: wrap;
          padding: 20px 0 10px;
        }
        >div{
          background-color: #f2f2f2;
          border-radius: 12px;
          max-width: 100px;
          padding-bottom: 3px;
          @media screen and (max-width: 1439px) {
            max-width: 130px;
          }
          @media screen and (max-width: 1000px) {
            margin-bottom: 10px;
          }
          .dial{
            width: 70px;
            aspect-ratio: 1/1;
            margin: 0 auto;
          }
          .op_mode{
            height: calc(2rem + 10px);
            margin: 5px;
          }
          p{
            margin: 0 10px;
            color: #787878;
            text-align: center;
            line-height: 16px;
          }
        }
        >div:not(:last-child){
          @media screen and (max-width: 820px) {
            margin-right: 10px;
          }
        }
      }
      .mts_right{
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 16px;
        @media screen and (max-width: 820px) {
          width: 100%;
          margin-bottom: 16px;
          padding-right: 0;
        }
        .eight_gages{
          border: 1px solid #bcbcbc;
          border-radius: 12px;
          padding: 6px 0 0 6px;
          display: flex;
          flex-wrap: wrap;
          max-width: 375px;
          // margin-top: 10px;
          @media screen and (max-width: 820px) {
            margin-top: 0px;
            margin-bottom: 16px;
            max-width: none;
          }
          >div{
            width: calc(50% - 6px);
            margin-right: 6px;
            margin-bottom: 6px;
            display: flex;
            border-radius: 8px;
            background-color: #7878780D;
            height: 45px;
            p{
              margin: 0;
              align-self: center;
              text-align: right;
              flex: 1;
              color: #787878;
              line-height: 16px;
            }
            .eight_light{
              height: 18px;
              aspect-ratio: 1/1;
              align-self: center;
              margin-left: 10px;
              margin-right: 10px;
            }
            .eight_dial{
              aspect-ratio: 8/5;
            }
            .eight_dial_standalone{
                height: calc(100% - 10px);
                flex-shrink: 0;
                aspect-ratio: 9/5;
                background-color: #787878;
                border-radius: 8px;
                color: #fff;
                margin: 5px;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
            }
          }
        }
        .wevlet{
          padding: 0;
          flex-shrink: 0;
          width: 100%;
          @media screen and (max-width: 560px) {
            flex-shrink: 0;
            width: 100%;
          }
          // height: calc(100% - 40px);
        }
      }
    }
    .lcl_data{
      @media screen and (min-width: 1440px){
        right: 0px;
      }
      @media screen and (max-width: 1100px) {
        flex-direction: initial;
        flex-wrap: wrap;
        justify-content: space-around;
      }
      @media screen and (max-width: 920px) {
        padding: 15px 15px 0;
      }
      .lcl_middle{
        max-width: 325px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        margin-right: 15px;
        justify-content: space-around;
        @media screen and (max-width: 1439px){
          margin-right: 0px;
        }
        @media screen and (max-width: 560px) {
          max-width: none;
        }
        .angle_displays{
          display: flex;
          flex-wrap: wrap;
          position: relative;
          // margin: auto 0;
          .rollAngleDisp,.heightAndPitchDisp,.yawDisp,.imageIndDisp {
            width: calc(50% - 4px);
            box-sizing: border-box;
            aspect-ratio: 1/1;
            margin: 2px;
          }
          .fuel{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
          .mph{
            position: absolute;
            width: calc(50% - 4px);
            aspect-ratio: 1/1;
            bottom: 0px;
            right: 0px;
            margin: 2px
          }
          .imageIndDisp {}
          .rollAngleDisp{}
          .heightAndPitchDisp {}
          .yawDisp {}
        }
        .pfr{
          width: 100%;
          box-sizing: border-box;
          margin-top: 10px;
          margin-bottom: 10px;
          padding: 0px;
          background-color: #f2f2f2;
          border-radius: 6px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          aspect-ratio: 2/1;
          max-width: 325px;
          align-items: center;
          position: relative;
          @media screen and (max-width: 560px) {
            align-self: center;
          }
          .wheels{
            position: absolute;
            padding: 1px 10px;
            margin: 0px;
            top: 50%;
            transform: translateY(-50%);
            max-width: fit-content;
            height: max-content;
            z-index: 2;
            left: 51px;
            border: 1px solid #bcbcbc;
            background-color: #fff;
            color: #787878;
            font-size: 0.8rem;
            @media screen and (max-width: 1439px) {
              left: 44px;
            }
          }
          >div{
            display: flex;
            flex-direction: row;
            // align-content: center;
            height: 78px;
            width: 78px;
            color: #787878;
          }
          .drive_mode,.dir{
            width: 125px;
            height: 67px;
            background-color: #787878;
            border-radius: 8px;
            display: inline-block;
            color: #fff;
            font-size: 0.8rem;
            text-align: center;
            margin: 5px 0;
            position: relative;
            padding-top: 15px;
            box-sizing: border-box;
          }
          .dir::after{
            content: 'Direction';
            position: absolute;
            color: #fff;
            bottom: 5px;
            left: 50%;
            transform: translateX(-50%);
          }
          .drive_mode::after{
            content: 'Drive Mode';
            position: absolute;
            color: #fff;
            bottom: 5px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
          }
        }
      }
      .lcl_right{
        margin-bottom: 10px;
        min-width: 122px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-content: flex-start;
        padding: 10px 30px 10px 10px;
        box-sizing: border-box;
        justify-content: space-between;
        flex-grow: 1;
        max-width: fit-content;
        @media screen and (max-width: 820px) {
          min-width: auto;
          max-width: none;
        }
        @media screen and (max-width: 720px) {
          min-width: 100%;
          flex-direction: row;
          max-width: none;
          padding: 10px 0 10px;
        }
        @media screen and (max-width: 650px) {
          flex-direction: row;
          flex-wrap: wrap;
          max-width: 100%;
          justify-content: space-around;
          align-content: center;
          margin-bottom: 0px;
        }
        .double_side_disp{
          // width: 122px;
          height: 33%;
          box-sizing: border-box;
          aspect-ratio: 1/1;
          @media screen and (max-width: 720px) {
            height: 150px;
          }
          @media screen and (max-width: 650px) {
            height: auto;
            width: 45%;
          }
        }
        p{
          color: #787878;
          margin: 0;
          padding-right: 25px;
          position: relative;
          box-sizing: border-box;
          height: 26px;
          @media screen and (max-width: 560px) {
            flex: 0 0 50%;
            padding-left: 10px;
            margin-bottom: 10px;
            align-content: center;
            // padding-right: 0;
          }
          div{
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            @media screen and (max-width: 560px) {
              // right: 20px;
            }
          }
        }
      }
      .lcl_left{
        display: flex;
        flex-direction: column;
        padding: 10px 0 10px 20px;
        flex-wrap: wrap;
        max-height: 500px;
        flex-grow: 1;
        max-width: 30%;
        @media screen and (max-width: 820px) {
          // flex-direction: initial;
          // justify-content: space-around;
          // flex-wrap: wrap;
          padding: 20px 0 10px;
          flex-grow: 0;
          max-width: none;
        }
        @media screen and (max-width: 560px) {
          justify-content: center;
          max-width: 100%;
          flex-direction: initial;
          padding-top: 0;
        }
        .bypass{
          text-align: center;
          >div{
            background-color: #787878;
            border-radius: 50%;
            display: inline-block;
            width: 60px;
            height: 60px;
            aspect-ratio: 1 / 1;
            margin: 5px auto;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
          }
          .blinking{
            box-sizing: border-box;
            -webkit-animation: bypass 1s infinite;  /* Safari 4+ */
            -moz-animation: bypass 1s infinite;  /* Fx 5+ */
            -o-animation: bypass 1s infinite;  /* Opera 12+ */
            animation: bypass 1s infinite;  /* IE 10+, Fx 29+ */
            animation-iteration-count: 3;
            background-color: #cf1b1b;
          }
          @keyframes bypass {
            0%, 49% {
              border: 12px solid #787878;
              background-color: #787878;
            }
            50%, 100% {
              border: 12px solid #b9b93f;
              background-color: #cf1b1b;
            }
          }
        }
        .hydraulic{
          text-align: center;
          >div{
            background-color: #787878;
            border-radius: 50%;
            display: inline-block;
            width: 60px;
            height: 60px;
            aspect-ratio: 1 / 1;
            margin: 5px auto;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
          }
        }
        .speed_guidance_system{
          text-align: center;
          >div{
            background-color: #787878;
            border-radius: 50px;
            display: inline-block;
            width: 60px;
            height: 60px;
            aspect-ratio: 1 / 1;
            margin: 5px auto;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
          }
          .is_on{
            background-image: url(../../public/img/boeing/on.svg);
            background-size: 110%;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        .stabilizer{
          text-align: center;
          >div{
            background-color: #787878;
            border-radius: 8px;
            display: inline-block;
            width: 60px;
            height: 60px;
            aspect-ratio: 1 / 1;
            margin: 5px auto;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
          }
          .blinking{
            box-sizing: border-box;
            -webkit-animation: moving 1s infinite;  /* Safari 4+ */
            -moz-animation: moving 1s infinite;  /* Fx 5+ */
            -o-animation: moving 1s infinite;  /* Opera 12+ */
            animation: moving 1s infinite;  /* IE 10+, Fx 29+ */
          }
          @keyframes moving {
            0%, 49% {
              background-color: #b9b93f;
            }
            50%, 100% {
              background-color: #787878;
            }
          }
        }
        >div{
          background-color: #f2f2f2;
          border-radius: 12px;
          max-width: 80px;
          padding-bottom: 3px;
          margin-bottom: 7px;
          @media screen and (max-width: 1439px) {
            max-width: 90px;
            margin-right: 20px;
            margin-bottom: 10px;
          }
          @media screen and (max-width: 1000px) {
            margin-bottom: 10px;
          }
          @media screen and (max-width: 560px) {
            margin-right: 10px;
            margin-left: 10px;
          }
          .dial{
            width: 70px;
            aspect-ratio: 1/1;
            margin: 0 auto;
          }
          .dial2{
            width: 70px;
            aspect-ratio: 1/1;
            margin: 0 auto;
          }
          .op_mode{
            height: calc(2rem + 10px);
            margin: 5px;
          }
          p{
            margin: 0 10px;
            color: #787878;
            text-align: center;
            line-height: 16px;
            @media screen and (max-width: 1439px) {
              font-size: 0.82rem;
              line-height: 1rem;
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
  .context_shadow {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    bottom: 0px;
    top: 0px;
    left: 0px;
    right: 0px;
  }
  .dualDial1Disp {
    position: absolute;
    top:20px;
    left:200px;
    height:200px;
    width:200px;
  }
  .dial1Disp {
    position: absolute;
    top:20px;
    left:120px;
    height:200px;
    width:200px;
  }
  .ind1Disp {
    position: absolute;
    top:200px;
    left:110px;
    height:100px;
    width:100px;
  }
  .ind2Disp {
    position: absolute;
    height:100%;
    aspect-ratio: 1/1;
    max-height: 18px;
  }
  .jsonViewDisp {
    overflow-y:auto;
    margin-top: 12px;
  }
</style>
