<template>
  <div class="container">
    <div class="innerCircle"></div>
    <highcharts class="gauge" :options="chartOptions" ref="highcharts" ></highcharts>

    <highcharts class="gauge_two" :options="chartOptionsTwo" ref="highcharts" ></highcharts>
    <div class="text_data">
      <div class="title">{{ title }}<span v-if="title == 'oil'">PSI</span><span v-if="title == 'temp'">° F</span></div>
      <div class="value" v-if="title == 'rpm'"><span>E1<br/>{{ Math.ceil(val*20) }}</span><span>E2<br/>{{ Math.ceil(valTwo*20) }}</span></div>
      <div class="value" v-else-if="title == 'oil'"><span>E1<br/>{{ Math.ceil(val*1.001) }}</span><span>E2<br/>{{ Math.ceil(valTwo*1.001) }}</span></div>
      <div class="value" v-else-if="title == 'temp'"><span>E1<br/>{{ Math.ceil(val*2) }}</span><span>E2<br/>{{ Math.ceil(valTwo*2) }}</span></div>
    </div>
  </div>
</template>

<script setup>
    import { ref, toRefs, watch } from 'vue';
    const props = defineProps({valin_one: Number, valin_two: Number, title: String});
    let val = ref(0);
    let valTwo = ref(0);

    let titleval = ref("");
    let title = props.title;
    
    let chartOptions = ref({});
    let chartOptionsTwo = ref({});
    chartOptions.value = {
      chart: {
        type: 'solidgauge',
        backgroundColor: 'rgba(0,0,0,0)',
        height:null,
        width:null,
        animation:true,
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      legend: {
        enabled: false
      },
      title: {
        text: ''
      },
      pane: {
        startAngle: -150,
        endAngle: -10,
        background: [{ 
          outerRadius: '92%',
          innerRadius: '65%',
          backgroundColor:'#787878',
          borderWidth: 0
        },]
      },
      plotOptions: {
        solidgauge: 
        {
          dataLabels: {
            enabled: false
          },
          stickyTracking: false,
          rounded: false,
          animation: false,
              // rounded: true,
        }
      },
      yAxis: {
        min: 0,
        max: 100,
        lineWidth: 0,
        tickPositions: [],
      },
      series: [{
        name: 'Percent Complete',
        data: [{
          color: 'white',
          radius: '120%',
          innerRadius: '105%',
          y: 0,
        }],
      }],
      };
        chartOptionsTwo.value = {
          chart: {
            type: 'solidgauge',
            backgroundColor: 'rgba(0,0,0,0)',
            height:null,
            width:null,
            animation:true,
          },
          credits: {
            enabled: false
          },
          exporting: {
            enabled: false
          },
          legend: {
            enabled: false
          },
          title: {
            text: ''
          },
          pane: {
            startAngle: -150,
            endAngle: -10,
            background: [{ 
                outerRadius: '92%',
                innerRadius: '65%',
                backgroundColor:'#787878',
                borderWidth: 0
            },]
          },
          plotOptions: {
            solidgauge: 
            {
              dataLabels: {
                enabled: false
              },
              stickyTracking: false,
              rounded: false,
              animation: false,
              // rounded: true,
            }
          },
          yAxis: 
          {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickPositions: [],
          },
          series: [{
            name: 'Percent Complete',
            data: [{
              color: 'white',
              radius: '120%',
              innerRadius: '105%',
              y: 0,
            }],
          }],
    };

    watch(props, () => {
      // if (props.title === 'rpm') { 
      //   props.valin_one = Math.ceil((props.val_left / 2000) * 95 - 30);
      //   right.value = Math.ceil(137 - (props.val_right / 2000) * 95);
      //   leftActual.value = props.val_left;
      //   rightActual.value = props.val_right;
      // } else if (props.title === 'oil') { 
      //   left.value = Math.ceil((props.val_left / 100) * 95 - 30);
      //   right.value = Math.ceil(137 - (props.val_right / 100) * 95);
      //   leftActual.value = props.val_left;
      //   rightActual.value = props.val_right;
      // } else if (props.title === 'temp') { 
      //   left.value = Math.ceil((props.val_left / 200) * 95 - 30);
      //   right.value = Math.ceil(137 - (props.val_right / 200) * 95);
      //   leftActual.value = props.val_left;
      //   rightActual.value = props.val_right;
      // }
  
      if(props.valin_one!==undefined) {
        chartOptions.value.series[0].data[0].y = props.valin_one;
        val.value = props.valin_one;
        titleval.value = props.title;
      } else {
        chartOptions.value.series[0].data[0].y = 0;
        val.value = 0;
        titleval.value = "";
			}
				
      if(props.valin_two!==undefined) {
        chartOptionsTwo.value.series[0].data[0].y = props.valin_two;
        valTwo.value = props.valin_two;
        titleval.value = props.title;
      } else {
        chartOptionsTwo.value.series[0].data[0].y = 0;
        val.value = 0;
        titleval.value = "";
      }
    });

   

</script>

<style lang="scss" scoped>
.container {
  position:relative;
  contain: layout;
}
.gauge {
  position:absolute;
  top: 2px;
  height:100%;
  width:100%;
  z-index:3;
}
.gauge_two{
  position:absolute;
  top: 2px;
  height:100%;
  width:100%;
  z-index:3;
  -moz-transform: scale(-1, 1);
  -webkit-transform: scale(-1, 1);
  -o-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.text_data{
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .title,.value{
    font-size: inherit;
    color:white;
  }
  .value {
    display: flex;
    text-align: center;
    width:100%;
    justify-items: center;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    margin-top: 10px;
    max-height: 2rem;
    font-size: 0.8rem;
    span{
      margin: 0 15px;
    }
  }
  .title {
    text-align: center;
    width: 100%;
    color: #fff;
    font-size: 1.2rem;
    text-transform: uppercase;
    span{
      display: block;
      font-size: 0.8rem;
      margin-top: 5px;
    }
  }
}
  .innerCircle {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: #787878;
    border-radius: 50%;
    display: inline-block;
    z-index: 2;
  }
</style>